import React from "react";
import PropTypes from "prop-types";
import { graphql, Link } from "gatsby";
import { getImage } from "gatsby-plugin-image";

import Layout from "../components/Layout";
import FullWidthImage from "../components/FullWidthImage";
import ScrollDivider from "../components/ScrollDivider";

// eslint-disable-next-line
export const IndexPageTemplate = ({
  image,
  title,
  color,
  heading,
  subheading,
}) => {
  const heroImage = getImage(image) || image;

  // const opts = {
  //   height: "480",
  //   width: "100%",
  //   playerVars: {
  //     // https://developers.google.com/youtube/player_parameters
  //     autoplay: 0,
  //     showinfo: 0,
  //     modestbranding: 1,
  //   },
  // };

  return (
    <div>
      <FullWidthImage
        img={heroImage}
        title={title}
        subheading={subheading}
        height={"100vh"}
      />

      <ScrollDivider color={color} button={true} anchor="/#main" />

      <section
        className="section section--gradient"
        style={{ background: color }}
      >
        <div className="container" id="main">
          <div className="section">
            <div className="columns">
              <div className="column is-10 is-offset-1">
                <div className="content">
                  <div className="columns is-multiline">
                    <div className="column is-12 promote">
                      <h3 className="has-text-weight-semibold is-size-3 has-text-centered">
                        02/09 juli 2023, Lustrumweek der A.R.S.R. "Skadi"
                      </h3>
                      <Link
                        to="/programma"
                        className="promote-button center"
                        style={{ backgroundColor: "#e3decc", color: "#00412d" }}
                      >
                        NAAR HET PROGRAMMA
                      </Link>
                    </div>

                    <div className="column is-12 promote">
                      <h3 className="has-text-weight-semibold is-size-3 has-text-centered">
                        {heading}
                      </h3>
                      <Link
                        to="http://www.youtube.com/watch?v=hnz17mjemNE"
                        rel="noopener"
                        target="_blank"
                        className="promote-button center"
                        style={{ backgroundColor: "#e3decc", color: "#00412d" }}
                      >
                        BEKIJK DE VIDEO
                      </Link>
                      {/* <YouTube videoId="hnz17mjemNE" opts={opts} /> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

IndexPageTemplate.propTypes = {
  image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  title: PropTypes.string,
  heading: PropTypes.string,
  subheading: PropTypes.string,
  color: PropTypes.string,
};

const IndexPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark;

  return (
    <Layout navColor="#00412D">
      <IndexPageTemplate
        image={frontmatter.image}
        title={frontmatter.title}
        color={frontmatter.color}
        heading={frontmatter.heading}
        subheading={frontmatter.subheading}
      />
    </Layout>
  );
};

IndexPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
};

export default IndexPage;

export const pageQuery = graphql`
  query IndexPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "index-page" } }) {
      frontmatter {
        title
        image {
          childImageSharp {
            gatsbyImageData(quality: 100, layout: FULL_WIDTH)
          }
        }
        color
        heading
        subheading
      }
    }
  }
`;
